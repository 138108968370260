import { graphql, navigate } from 'gatsby';
import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { LayoutContext } from '../components/organisms/layoutProvider';
import { media } from '../utils/media-queries';
import SEO from '../components/organisms/seo';

const ContactPage = ({ data }) => {
  const { language } = useContext(LayoutContext);

  useEffect(() => {
    navigate(language === 'en' ? '/contact' : '/de/contact', { replace: true });
  }, [language]);

  const {
    title,
    mail,
    facebook,
    instagram,
    pictofolio,
  } = data.contact.nodes[0];

  return (
    <>
      <SEO siteTitle={title[language] || title.en} />
      <StyledContact className="spacing-inner">
        <p className="title">{title[language] || title.en}</p>
        <div className="contact">
          <div className="mail">
            <a
              className="hover"
              href={`mailto:${mail}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {mail}
            </a>
          </div>
          <div className="social">
            <a
              className="hover"
              href={facebook}
              target="_blank"
              rel="noopener noreferrer"
            >
              FB
            </a>
            <a
              className="hover"
              href={pictofolio}
              target="_blank"
              rel="noopener noreferrer"
            >
              PCTFL
            </a>
            <a
              className="hover"
              href={instagram}
              target="_blank"
              rel="noopener noreferrer"
            >
              INST
            </a>
          </div>
        </div>
      </StyledContact>
    </>
  );
};

const StyledContact = styled.section`
  min-height: 100vh;
  margin-top: var(--v-spacing-S);

  .title {
    font-family: var(--font-1);
    padding-bottom: var(--v-spacing-M);
  }

  .contact {
    display: flex;

    .mail {
      width: 66%;
      font-family: var(--font-1);
      padding-bottom: var(--spacing-XS);
    }

    .social {
      display: flex;
      flex-direction: column;
    }
  }

  @media ${media.L} {
    .contact {
      .mail {
        width: 33%;
      }
    }
  }

  @media (max-width: 349px) {
    .contact {
      flex-direction: column;
    }
  }
`;

export const query = graphql`
  query Contact {
    contact: allSanityContact {
      nodes {
        title {
          de
          en
        }
        mail
        facebook
        instagram
        pictofolio
      }
    }
  }
`;

ContactPage.propTypes = {
  data: PropTypes.object,
};

export default ContactPage;
